import { IStorageContainer } from "./IStorageContainer";


export class StorageContainer<TValue> implements IStorageContainer<TValue> {

    private storageKey: string;

    constructor(storageKey: string) {
        this.storageKey = storageKey;
    }


    public GetItem(): TValue | undefined {

        const item = localStorage.getItem(this.storageKey);

        if (item === null) {

            return undefined;

        }

        return JSON.parse(item);

    }

    public SetItem(value: TValue): void {

        localStorage.setItem(this.storageKey, JSON.stringify(value));

    }

    public RemoveItem(): void {

        localStorage.removeItem(this.storageKey);

    }



}