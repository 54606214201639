import { faUniversity } from "@fortawesome/pro-light-svg-icons";
import { Grid, LinearProgress, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { defaultRecordCarreraConfig } from "../../../../data/defaults/records/defaultRecordCarreraConfig";
import { usePropChange } from "../../../../hooks/Shared/usePropChange/usePropChange";
import { useValidateCarreraConfig } from "../../../../hooks/validators/useValidateCarreraConfig/useValidateCarreraConfig";
import { IRecordCarreraConfig } from "../../../../interfaces/Records/IRecordCarreraConfig";
import { IFormInteractionEditRecord } from "../../../../interfaces/Shared/Forms/IFormInteractionEditRecord";
import { IndicatorHeaderSection } from "../../../Shared/IndicatorHeaderSection/IndicatorHeaderSection";
import { DateTimePicker } from "@mui/x-date-pickers";
import { ComboServiceSelector } from "../../../Shared/ComboServiceSelector/ComboServiceSelector";
import { IRecordRecinto } from "../../../../interfaces/Records/IRecordRecinto";
import { useContextApiService } from "../../../../contexts/ContextApiService/ContextApiService";
import { IRecordModalidad } from "../../../../interfaces/Records/IRecordModalidad";
import { IRecordCarrera } from "../../../../interfaces/Records/IRecordCarrera";
import { IRecordLineaNegocio } from "../../../../interfaces/Records/IRecordLineaNegocio";
import { defaultRecordRecinto } from "../../../../data/defaults/records/defaultRecordRecinto";
import { defaultRecordModalidad } from "../../../../data/defaults/records/defaultRecordModalidad";
import { defaultRecordLineaNegocio } from "../../../../data/defaults/records/defaultRecordLineaNegocio";
import { defaultRecordCarrera } from "../../../../data/defaults/records/defaultRecordCarrera";


export const FormAdminCarreraConfig = ({

    defaultRecord,
    onRecordChanged

}: IFormInteractionEditRecord<IRecordCarreraConfig>) => {

    const { ApiService } = useContextApiService();
    const { Record, OnTextInputChanged, OnPropChanged } = usePropChange<IRecordCarreraConfig>(
        defaultRecord ?? defaultRecordCarreraConfig()
    );
    const [reloadCarreras, setReloadCarreras] = useState<boolean>(false);
    const { validate: validateCarrera } = useValidateCarreraConfig();


    const onSelectionRecinto = (record: IRecordRecinto | undefined) => {


        if (record === undefined) {
            OnPropChanged("recintoId", "");
            return;
        }

        console.log("Recinto", record);
        OnPropChanged("recintoId", record.chainId);


    }

    const onSelectionModalidad = (record: IRecordModalidad | undefined) => {

        if (record === undefined) {
            OnPropChanged("modalidadId", "");
            return;
        }

        console.log("Modalidad", record);
        OnPropChanged("modalidadId", record.chainId);

    }


    const onSelectionLinea = (record: IRecordLineaNegocio | undefined) => {

        setReloadCarreras(true);

        if (record === undefined) {
            OnPropChanged("lineaNegocioId", "");
            return;
        }

        console.log("Linea", record);
        OnPropChanged("lineaNegocioId", record.chainId);

        setTimeout(() => {

            setReloadCarreras(false);

        }, 500);

    }

    const onSelectionCarrera = (record: IRecordCarrera | undefined) => {

        console.info("Seleccion carrera trigger")

        if (record === undefined) {
            //OnPropChanged("carreraId", "");
            return;
        }

        console.log("Carrera", record);
        OnPropChanged("carreraId", record.chainId);

    }

    useEffect(() => {

        if (validateCarrera(Record)) {

            console.log("Record is valid", Record);
            onRecordChanged(Record)

        }

    }, [Record]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} container spacing={3}>
                <IndicatorHeaderSection
                    title="Datos Generales"
                    icon={faUniversity}
                />
                <Grid container item xs={12} spacing={3}>
                    <Grid item xs={12} md={6}>
                        <ComboServiceSelector<IRecordRecinto>
                            id="combo-campus"
                            label="Campus"
                            isRequired
                            defaultRecord={{
                                ...defaultRecordRecinto(),
                                chainId: Record.recintoId
                            }}
                            queryService={() => {
                                return ApiService.Admin.Recintos.GetByUniversidadId(Record.universidadId);
                            }}
                            onSelectionChange={onSelectionRecinto}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ComboServiceSelector<IRecordModalidad>
                            id="combo-modalidad"
                            label="Modalidad"
                            isRequired
                            defaultRecord={{
                                ...defaultRecordModalidad(),
                                chainId: Record.modalidadId
                            }}
                            queryService={() => {
                                return ApiService.Admin.Modalidades.GetActive();
                            }}
                            onSelectionChange={onSelectionModalidad}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ComboServiceSelector<IRecordLineaNegocio>
                            id="combo-linea"
                            label="Linea de Negocio"
                            isRequired
                            defaultRecord={{
                                ...defaultRecordLineaNegocio(),
                                chainId: Record.lineaNegocioId
                            }}
                            queryService={() => {
                                return ApiService.Admin.LineaNegocio.GetActive();
                            }}
                            onSelectionChange={onSelectionLinea}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {
                            !reloadCarreras ? (
                                <ComboServiceSelector<IRecordCarrera>
                                    id="combo-carreras"
                                    label="Carrera"
                                    isRequired
                                    defaultRecord={{
                                        ...defaultRecordCarrera(),
                                        chainId: Record.carreraId
                                    }}
                                    queryService={() => {
                                        return ApiService.Admin.Carreras.GetByLineaNegocioId(Record.lineaNegocioId);
                                    }}
                                    onSelectionChange={onSelectionCarrera}
                                />
                            ) : (
                                <LinearProgress />
                            )
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        value={Record.duracion}
                        label="Duración"
                        variant="outlined"
                        fullWidth
                        name="duracion"
                        type="number"
                        onChange={OnTextInputChanged("duracion")}
                        error={Record.duracion <= 0}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        value={Record.primerPago}
                        label="Primer Pago"
                        variant="outlined"
                        fullWidth
                        name="primerPago"
                        type="number"
                        onChange={OnTextInputChanged("primerPago")}
                        error={Record.primerPago <= 0}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        value={Record.costoTotal}
                        label="Costo Total"
                        variant="outlined"
                        fullWidth
                        name="costoTotal"
                        type="number"
                        onChange={OnTextInputChanged("costoTotal")}
                        error={Record.costoTotal <= 0}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        value={Record.colegiatura}
                        label="Colegiatura"
                        variant="outlined"
                        fullWidth
                        name="colegiatura"
                        type="number"
                        onChange={OnTextInputChanged("colegiatura")}
                        error={Record.colegiatura <= 0}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <DateTimePicker
                        disablePast
                        label="Vigencia Inicio"
                        value={Record.vigenciaInicio}
                        onChange={(newValue) => {
                            OnPropChanged("vigenciaInicio", newValue);
                        }}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                error: Record.vigenciaInicio === null,
                                required: true
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <DateTimePicker
                        disablePast
                        label="Vigencia Fin"
                        value={Record.vigenciaFin}
                        onChange={(newValue) => {
                            OnPropChanged("vigenciaFin", newValue);
                        }}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                error: Record.vigenciaFin === null,
                                required: true
                            }
                        }}
                    />
                </Grid>
            </Grid>

        </Grid>
    );

}