

export const formatCurrency = (value: number): string => {

    return value.toLocaleString('es-MX', { style: 'currency', currency: 'MXN', minimumFractionDigits: 0, maximumFractionDigits: 0 });

}


export const formatDuracion = (duracion: number): string => {

    const years = Math.floor(duracion / 12);
    const months = duracion % 12;

    if (months <= 0) {
        return `${years} años`;
    }

    return `${years} años ${months} meses`;

}